import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
export default function useQueueList() {
  console.log('useQueueList',router.currentRoute.name);
  const searchQuery = ref('');
  const selected_group = ref('');
  const selected_status = ref('');
  const searchDate = ref(new Date().toISOString().slice(0,10));
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const Patient_Data = ref([]);
  const Doctor_Data = ref([]);
  const Room_Data = ref([]);
  const dir = ref(false);
  const toast = useToast()

  const tableColumns = [

    { field: 'code', label: 'เลขที่ OPD', sortable: false},
    { field: 'receipt_no', label: 'เลขที่ใบเสร็จ', sortable: false},
    { field: 'datetime', label: 'วันที่', sortable: false},
      { field: 'full_name', label: 'ลูกค้า', sortable: false},
        { field: 'doctor_full_name', label: 'แพทย์', sortable: false },
        { field: 'room_name', label: 'ห้อง', sortable: false },
        { field: 'status', label: 'สถานะ', sortable: false },
        { field: 'check_status', label: 'สถานะตรวจ', sortable: false },
        { field: 'action', label: 'ตัวเลือก', sortable: false },



    ]
    const Data = ref([]);
    const current_name = ref(router.currentRoute.name);
    console.log('current_name',current_name.value);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const refetchData = () => {
      refListTable.value.refresh()
  }

    watch([currentPage, pageLength, searchQuery, selected_status, searchDate], () => {
      fetchData();
    })
  
       watch(() => router.currentRoute.name, () => {
      console.debug(`MyCoolComponent- watch root.$route.name changed to`);
    });

    const fetchData = async () =>{
      console.log('current_name',current_name);
     // let status = 1;
      if(current_name == 'queue-service'){

      }
      const offset = pageLength.value*(currentPage.value-1);
      Data.value =  [];
   //   console.log('queue_id',queue_id);
      await store
            .dispatch('app-queue/fetchData', {
             // id:queue_id,
              limit: pageLength.value,
              offset:offset,
              keywords: searchQuery.value,
              date:searchDate.value,
              branch_id:branch_id,
              order_by:'id DESC',
            //  status:status
          
            })
            .then(response => {
              Data.value = response.data.data;

              const total = response.data.total?response.data.total: Data.value.length;
              Total.value = total;
            })
            .catch((error) => {
              console.log('error',error);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching queue list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    }

    fetchData();

    const fetchPatientData = async () =>{
        Patient_Data.value  = [];
        await store
              .dispatch('app-queue/fetchPatientData', {
                branch_id:branch_id,
                order_by:'id DESC'
            
              })
              .then(response => {
                Patient_Data.value = response.data.data;
                console.log('Patient_Data',Patient_Data);
              })
              .catch((error) => {
                console.log('error',error);
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error fetching Doctor list",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
      }

    const fetchDoctorData = async () =>{
        Doctor_Data.value  = [];
        await store
              .dispatch('app-queue/fetchDoctorData', {
                //branch_id:branch_id,
                group_id:7,
                order_by:'id DESC'
            
              })
              .then(response => {
                Doctor_Data.value = response.data.data;
              })
              .catch((error) => {
                console.log('error',error);
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error fetching Doctor list",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
      }
    
      const fetchRoomData = async () =>{
        Room_Data.value  = [];
        await store
              .dispatch('app-queue/fetchRoomData', {
               // branch_id:branch_id,
                is_active:1,
                order_by:'rooms.id DESC'
            
              })
              .then(response => {
                Room_Data.value = response.data.data;
              })
              .catch((error) => {
                console.log('error',error);
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error fetching Room list",
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
      }
    
    fetchPatientData();
    fetchDoctorData();
    fetchRoomData();
    const deleteData = (id) => {
      //fetchDeleteProperty
      var data = {id:id}
      var res = store.dispatch('app-queue/deleteData',data)

      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }
    const cancelData = (id) => {
      //fetchDeleteProperty
      var data = {id:id}
      var res = store.dispatch('app-queue/cancelData',data)

      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }

    const resolveUserStatusVariant = status => {
       
      if (status) return 'success'
      if (!status) return 'secondary'
      return 'primary'
    }
    const resolveUserStatusnameVariant = status => {
    
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }

    const resolveStatusVariant = status =>{
      if (status) return 'primary'
      if (!status) return 'mute'
        return 'primary'
    }

    const resolveStatusTextVariant = (item) =>{
      if(item.receipt_id && item.status != 3){
        return 'เสร็จสิ้น'
      }else{
        if(item.check_status == 1){
          return 'รอตรวจ'
        }else if(item.check_status == 2){
          return 'รอใช้บริการ'
        }
        else if(item.check_status == 3){
          return 'ยกเลิก'
        }
        else if(item.check_status == 4){
          return 'ตรวจเสร็จสิ้น'
        }
        else if(item.status == 1){
          return 'รอตรวจ'
        }else if(item.status == 2){
          return 'รอชำระเงิน'
        }
        else if(item.status == 3){
          return 'ยกเลิก'
        }
        else if(item.status == 4){
          return 'ตรวจเสร็จสิ้น'
        }
        else{
          return 'รอตรวจ'
        }


      }
     
    }

    const resolveStatusTextColorVariant = (item) =>{
      if(item.receipt_id){
        return 'primary'
      }else{
        if(item.check_status == 1){
          return 'warning'
        }else if(item.check_status == 2){
          return 'primary'
        }
        else if(item.check_status == 3){
          return 'danger'
        }
        else if(item.check_status == 4){
          return 'success'
        }
        else{
          return 'mute'
        }

      }
   
    }

    const resolveTreatmentStatusTextVariant = (item) =>{
      
      if(item.receipt_id){
       
        if(item.receipt_status == 4){
        
          return 'ชำระเงินแล้ว'
        }else if(item.receipt_status == 5){
          return 'ยกเลิก'
         
        }else{
          return 'รอชำระเงิน'
        }
       
      }
      else if(item.status == 1){
        return 'รอตรวจ'
      }else if(item.status == 2){
        return 'รอชำระเงิน'
      }
      else if(item.status == 3){
        return 'ยกเลิก'
      }
      else if(item.status == 4){
        return 'ตรวจเสร็จสิ้น'
      }
      else{
        return 'รอตรวจ'
      }
     
       
    }
    const resolveTreatmentStatusTextColorVariant =  (item) =>{
      if(item.receipt_id){
        if(item.receipt_status == 4){
        
          return 'success'
        }else if(item.receipt_status == 5){
          return 'danger'
         
        }else{
          return 'warning'
        }
        
      }
      else if(item.status == 1){
        return 'warning'
      }else if(item.status == 2){
        return 'primary'
      }
      else if(item.status == 3){
        return 'danger'
      }
      else if(item.status == 4){
        return 'success'
      }
      else{
        return 'mute'
      }
    }
    const  updateReceipt = async(params)=>{
      router.push({name:'receipt-edit',params:{id:params.receipt_id}, replace:true});

    }
    const createReceipt = async(params)=>{
      //opdcard_id
      console.log('create-receipt',params);
      let data = {}
      data.branch_id = params.branch_id;
      data.patients_id = params.patient_id; 
      data.opdcard_id = params.opdcard_id;
      data.tax_type = '0';
      data.tax_value = '0.00';
      data.discount_type = 1;
      data.discount_value = '0';
      data.total_items='0.00';
      data.price_total='0.00';
      data.deposit = '0';
      data.status_id = '0';
     const response =  await store.dispatch('app-queue/addReceipt',data);
     if(response.data.success){
      router.push({name:'receipt-edit',params:{id:response.data.data.id}, replace:true});
     }else{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Create Receipt",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

     }
    
     // router.push({ name: 'receipt-opd-create',params:{opdcard_id:opdcard_id}, replace: true });
      //   :to="{ name: 'receipt-opd-create', params:{opdcard_id:props.row.opdcard_id}}"

    }
    const AddService = async(params)=>{
      console.log('AddService',params)
      let data = {
        opdcard_id:params.opdcard_id,
        patients_id:params.patient_id,
        doctor_id:params.doctor_id,
        room_id:params.room_id,
        status:1,
        branch_id:params.branch_id,
        created_by:userStorage.id,
        date: new Date().toISOString().slice(0,10)

    }

      const result =  await store.dispatch('app-roomdetail/addQueueServiceData',data);
      if(result.data.success == true){
        let id = result.data.data.insertId;
        router.push({ name: 'queue-service-add',params:{id:id}, replace: true }).then(()=>{
          router.push({ name: 'queue-service', replace: true })
          toast({
            component: ToastificationContent,
            props: {
              title: "Create Service Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        });
      }else{
        toast({
          component: ToastificationContent,
          props: {
            title: "Create Serice  Error",
            icon: 'AlertTriangleIcon',
            variant: 'error',
          },
        })

      }
      //const result = await store.dispatch('app-roomdetail/addQueueServiceData',inputData);
    }
    return {
        tableColumns,
        Total,
        Data,
        Patient_Data,
        Doctor_Data,
        Room_Data,
        pageLength,
        dir,
        searchQuery,
        searchDate,
        selected_status,
        currentPage,
        refListTable,
        deleteData,
        cancelData,
        fetchData,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveStatusVariant,
        resolveStatusTextVariant,
        resolveStatusTextColorVariant,
        resolveTreatmentStatusTextVariant,
        resolveTreatmentStatusTextColorVariant,
        createReceipt,
        updateReceipt,
        AddService
    }
}